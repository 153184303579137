interface MonitoringFeature {
  title: string;
  text: string;
  plans: ('plus' | 'basic')[];
  alulaFeature?: boolean;
}

const features: MonitoringFeature[] = [
  {
    title: '24/7 live alarm monitoring',
    text: 'With three award-winning monitoring stations, you can rest assured that you will always have access to the authorities when you need it.',
    plans: ['basic', 'plus'],
  },
  {
    title: 'Protection from fires, smoke, and flooding',
    text: 'Monitored environmental protection can save you thousands of dollars in damages.',
    plans: ['basic', 'plus'],
  },
  {
    title: 'Alarm verification',
    text: 'Confirm or cancel your alarms through the Cove Connect app, via phone call, or by responding to our automated text message.',
    plans: ['basic', 'plus'],
  },
  {
    title: 'Faster response with InstaText™',
    text: 'Our automated text system will send you a message to confirm or cancel immediately after an alarm is triggered.',
    plans: ['basic', 'plus'],
  },
  {
    title: '24/7 customer support',
    text: 'Our friendly, knowledgeable customer service team is available around the clock to assist with technical issues and account management.',
    plans: ['basic', 'plus'],
  },
  {
    title: 'Dual connection',
    text: 'Stay connected to the monitoring team with wired ethernet connection and 4G cellular backup',
    plans: ['basic', 'plus'],
    alulaFeature: true,
  },
  {
    title: '24-hour battery backup',
    text: 'In the unlikely event that both ethernet and cellular connection are lost, your system hub will stay connected to the monitoring team for up to 24 hours.',
    plans: ['basic', 'plus'],
  },
  {
    title: 'Smartphone control',
    text: 'Keep tabs on your home from anywhere through the Cove Connect app. Set custom routines, arm and disarm your system, and more!',
    plans: ['basic', 'plus'],
    alulaFeature: true,
  },
  {
    title: '1-year equipment warranty',
    text: 'We don’t think you’ll need it, but if you ever have an issue with your equipment, we will fix it for up to one year.',
    plans: ['basic'],
  },
  {
    title: 'Lifetime equipment warranty',
    text: 'Maintaining your system won’t cost you a dime. Ever. If something ever goes wrong, we’ll fix it',
    plans: ['plus'],
  },
  {
    title: 'Alexa and Google Home voice control ',
    text: 'Use voice commands to arm, disarm, and check the status of your system.',
    plans: ['plus'],
  },
  {
    title: 'Camera support',
    text: 'Camera integration is only available through the Cove Plus plan.',
    plans: ['plus'],
  },
  {
    title: '$5/month equipment reward',
    text: 'Upgrade your security system on us with a $5 per month credit toward new equipment. You don’t have to do a thing. And the money rolls over at the end of the month if it isn’t used.',
    plans: ['plus'],
  },
];

interface GetPlanFeaturesProps {
  plan: 'plus' | 'basic';
  includeAlulaFeatures?: boolean;
}

const getPlanFeatures = ({
  plan,
  includeAlulaFeatures = false,
}: GetPlanFeaturesProps) => {
  return features.filter(feature => {
    if (includeAlulaFeatures) {
      return feature.plans.includes(plan);
    }
    return feature.plans.includes(plan) && !feature.alulaFeature;
  });
};

export { features, getPlanFeatures };
export type { MonitoringFeature };
